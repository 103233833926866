.wrapper {
  padding: 100px;
  color: 'black';

  & > p {
    font-size: 14px;
  }
}

.f14 {
  font-size: 12px !important;
}

.small {
  position: relative;
  z-index: 10;
  font-size: 8px !important;
}

.shift1 {
  margin-left: 50px;
  margin-bottom: 10px;
}
.shift2 {
  margin-left: 20px;
  margin-bottom: 10px;
}

.title {
  font-size: 20px !important;
  text-align: center;
}

.flex {
  display: flex;
}

.flex1 {
  display: flex;

  & > p {
    width: 50%;
    text-align: center;
  }
}

.flex2 {
  display: flex;
  justify-content: space-between;
}

.wrapper1 {
  display: flex;
  flex-direction: column;
}

.selfEnd {
  align-self: flex-end;
}

.center {
  align-self: center;
}

.shift3 {
  margin-left: 20px;
}

.shift4 {
  margin-left: 200px;
}

.gap1 {
  margin-bottom: 10px;
}

.flex3 {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 20px;
}

.flex4 {
  display: flex;
  gap: 40px;
}

.border {
  border: 2px solid gray;
  width: 150px;
  height: 60px;
  position: relative;
}

.tableWrapper {
  padding: 50px;
  display: flex;
  flex-direction: column;

   & > p {
    font-size: 11px;
  }
}

.app {
  font-weight: 700;
  font-size: 14px !important;
  align-self: flex-end;
}

.blueTable {
  border: 1px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  margin-bottom: 25px;
}

.cell {
  border: 1px solid #000000;
  padding: 1px 5px;
  font-size: 11px;
}

.w50 {
  width: 50%
}

.w15 {
  width: 15%
}

.bold {
  font-size: 14px !important;
  font-weight: 700;
}

.textEnd {
  text-align: right;
  font-weight: 700;
  font-size: 11px !important;
}

.sign {
  position: absolute;
  top: -10px;
  left: 180px;
  width: 130px;
  height: 80px;
}

.stamp {
  position: absolute;
  z-index: 1;
  left: 360px;
  top: -25px;
  width: 120px;
  height: 120px;
}

.sign2 {
  position: absolute;
  top: -20px;
  left: 40px;
  width: 130px;
  height: 80px;
}

.stamp2 {
  position: absolute;
  z-index: 1;
  left: 170px;
  top: -90px;
  width: 120px;
  height: 120px;
}

.stamp3 {
  position: absolute;
  z-index: 1;
  left: 360px;
  top: -90px;
  width: 120px;
  height: 120px;
}